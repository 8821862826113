import React from 'react';

const AppFooter = () => {
  return (
    <div className="container app-footer">
      Made with <span>&hearts;</span>
      {' by '}
      <a href="https://www.syahmifauzi.com/">Syahmi Fauzi</a>
    </div>
  );
};

export default AppFooter;
